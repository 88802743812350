import React, {  useEffect, useMemo, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';

import {  useTheme2,CustomScrollbar, Button, ConfirmModal, Tooltip, Input } from '@grafana/ui';
import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import DropdownCheckboxForm from './DropdownCheckboxForm';


interface Props extends PanelProps<SimpleOptions> {}

interface Option {
  value: string;
  label: string;
}



interface VematDevicesTable{
  id: number;
  name: string;
  coef: number | null;
  edited: boolean;
  showError: boolean;
  [key: string]: any;
}
interface Ierror {
  id: number;
  message: string;
  show: boolean;
}
type ReportSettings={
  deviceList: string[];
  groupName: string;
  shift: string[];
  reportRate: string;
  email: string;
  deviceNames: string[];
};
interface ReportTable{
id: number;
group_name: string;
settings: ReportSettings;
showModal: boolean;
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {

  const theme = useTheme2();

  const [devices,setDevices] = useState<VematDevicesTable[]>([]);
  const [ReportT,setReportT] = useState<ReportTable[]>([]);

  const [_formError, setFormError] = useState<Ierror[]>([]);
  const [selectedShift, setSelectedShift] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [update,setUpdate] = useState(false);
 




  const createQuery = (
    from: string,
    to: string,
    datasourceUID: string | null = null,
    rawSql: string
  ) => {
    return {
      from: from,
      to: to,
      queries: [
        {
          refId: 'A',
          datasource: {
            uid: datasourceUID
          },
          //datasourceId: datasourceId,
          rawSql: rawSql,
          format: 'table',
        },
      ],
    };
  };
  
  const fetchData = (
    apiUrl: string,
    method: string,
    headers: Record<string, string>,
    body?: any
  ): Promise<any> => {
    return fetch(apiUrl, {
      method: method,
      headers: headers,
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        return null;
      });
  };
  
  //1. fetch data
  const grafanaApiBaseUrl = options.api; // Replace with your Grafana instance URL  http://localhost:3000/api/ds/query
  const method = 'POST';
  const datasourceUID = data.request?.targets[0].datasource?.uid; // Replace with the data source ID you discovered earlier

const headers = useMemo(() => {
  // Replace the following with your actual header initialization logic
  return {
    //Authorization: 'Bearer your_access_token',
    'Content-Type': 'application/json',
  };
}, []);

  //2. onclick event handler
  //3. edit / delete
  const handleFormSubmit = async(
    selectedShift: Option[],
    selectedOptions: Option[],
     name: string,
      email: string
      ) => {
    
 
     if (!name.trim()) {
      
       return;
     }
 
     if (!email.trim()) {
       
       return;
     }
    
     
    
 
     const selectedOptionValues = selectedOptions.map((option) => option.value);
     const selectedShiftValues = selectedShift.map((option)=>option.value);
    // const joined = selectedOptionValues.join(",");
     console.log("seloptions ",selectedOptionValues);
     console.log("selectedshift ",selectedShiftValues)
     //console.log("Joined: ",joined);
     // Do something with the form data (e.g., submit to server)
     console.log('Selected Options:', selectedOptionValues);
     console.log('Name:', name);
     console.log('Phone:', email);
     
     const toS: ReportSettings = {
      groupName: name,
      deviceList: selectedOptionValues,
      email: email,
      reportRate: "weekly",
      shift: selectedShiftValues,
      deviceNames:[]
      
     };
    
    const sqlInsert = `INSERT INTO report_settings(group_name, settings) VALUES ('${name}', '${JSON.stringify(toS)}')`;
     console.log(sqlInsert);
    const updateQuery = createQuery('now-1h', 'now', datasourceUID, sqlInsert);
   await fetchData(grafanaApiBaseUrl, method, headers, updateQuery);

    reloadTable();
     // Reset form fields
     setSelectedOptions([]);
     setName('');
     setEmail('');
     setReportT([]);
   

   };

  const reloadTable = () =>{
    setUpdate(up=>!up);
  }

  useEffect(()=>{
    const createQuery = (
      from: string,
      to: string,
      datasourceUID: string | null = null,
      rawSql: string
    ) => {
      return {
        from: from,
        to: to,
        queries: [
          {
            refId: 'A',
            datasource: {
              uid: datasourceUID
            },
            //datasourceId: datasourceId,
            rawSql: rawSql,
            format: 'table',
          },
        ],
      };
    };
    
    const fetchData = (
      apiUrl: string,
      method: string,
      headers: Record<string, string>,
      body?: any
    ): Promise<any> => {
      return fetch(apiUrl, {
        method: method,
        headers: headers,
        credentials: 'include',
        body: body ? JSON.stringify(body) : undefined,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          return data;
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
          return null;
        });
    };

    const method = 'POST';
    const sqlCommands = [`SELECT address,name,coef FROM devices order by name asc`,
    `SELECT * FROM report_settings order by id asc`
  ];
    const select_vemat_devices = createQuery('now-1h', 'now', datasourceUID, sqlCommands[0]);
    const headers =  {
      'Content-Type': 'application/json',
    };
    const reload = async()=>{

      fetchData(grafanaApiBaseUrl, method, headers, select_vemat_devices).then(data => {
        if (data) {
          const ids = data.results.A.frames[0].data.values[0];
          const names = data.results.A.frames[0].data.values[1];
          const coefs = data.results.A.frames[0].data.values[2];
    
          const mappedData = ids.map((id: number, index: number) => ({
            id,
            name: names[index],
            coef: coefs[index],
            edited: false,
            showError: false,
          }));
          //map error array
    
          setDevices(dev=>mappedData);
          //napamovani nactenych dat do StateVariable
        }
      });
    }
    reload();
  //1. fetch devices await
  //2. fetch filters and bind.

  },[update,datasourceUID,grafanaApiBaseUrl]);


  //load existing stuff and write labels.
  useEffect(()=>{
    const createQuery = (
      from: string,
      to: string,
      datasourceUID: string | null = null,
      rawSql: string
    ) => {
      return {
        from: from,
        to: to,
        queries: [
          {
            refId: 'A',
            datasource: {
              uid: datasourceUID
            },
            //datasourceId: datasourceId,
            rawSql: rawSql,
            format: 'table',
          },
        ],
      };
    };
    
    const fetchData = (
      apiUrl: string,
      method: string,
      headers: Record<string, string>,
      body?: any
    ): Promise<any> => {
      return fetch(apiUrl, {
        method: method,
        headers: headers,
        credentials: 'include',
        body: body ? JSON.stringify(body) : undefined,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          return data;
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
          return null;
        });
    };

    if(devices.length!==0){
console.log("Devices set");


const method = 'POST';
const sqlCommands = [`SELECT address,name,coef FROM devices order by name asc`,
`SELECT * FROM report_settings order by id asc`
];
const select_vemat_report = createQuery('now-1h', 'now', datasourceUID, sqlCommands[1]);
const headers =  {
  'Content-Type': 'application/json',
};

  fetchData(grafanaApiBaseUrl, method, headers, select_vemat_report).then(data => {
    if (data) {
      //setReportT([]);
      const ids = data.results.A.frames[0].data.values[0];
      const names = data.results.A.frames[0].data.values[1];
      const settings = data.results.A.frames[0].data.values[2];

      const mappedData = ids.map((id: number, index: number) => ({
        id,
        group_name: names[index],
        settings: JSON.parse(settings[index]),
        showError: false,
      }));
      
      //map error array

      const errList: Ierror[] = [];
      for (let i = 0; i < mappedData.length; i++) {
        errList.push({ id: mappedData[i].id, message: '', show: false });
      }
      setFormError(errList);

      let _ReportT: ReportTable[]= mappedData;
      let _devices = devices;
      _ReportT.forEach(element => {
        let settings = element.settings;
        settings.deviceNames = [];
        for(let i=0;i<settings.deviceList.length;i++){
            let deviceId = parseInt(settings.deviceList[i],10);
            for(let j =0; j<_devices.length;j++){
              if(_devices[j].id===deviceId){
                settings.deviceNames.push(_devices[j].name);
                break;
              }
            }
        }
      });
      setReportT(_ReportT);
      setReportT(prev => mappedData);
     
      //napamovani nactenych dat do StateVariable
    }
  });
    };
  },[devices,datasourceUID,grafanaApiBaseUrl]);
/*
const handleChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  id: number,
  field: string
) => {
  const newData = devices.map((device, index) =>
    index === id ? { ...device, settings: { ...device.settings, [field]: event.target.value } } : device
  );
  setDevices(newData);

  // Clear the error for the specific field
  const newErrors = formError.map((error, index) =>
    index === id ? { ...error, show: false, message: '' } : error
  );
  setFormError(newErrors);
};
*/
  const handleDelete = (row: ReportTable,index: number,) => {
    const deleteSql = `DELETE FROM report_settings WHERE id = ${ReportT[index].id}`;
    const deleteQuery = createQuery('now-1h', 'now', datasourceUID, deleteSql);
    fetchData(grafanaApiBaseUrl, method, headers, deleteQuery);
    reloadTable();
  };
/*
  const table_theme = createTheme({
    overrides: {
      MuiFormControl:{
      root:{
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        color: theme.colors.primary.contrastText
      }
      },
      MuiInputBase:{
        input:{
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText
        }
      },
      MuiTableCell: {
        body:{backgroundColor: theme.colors.background.secondary,
          borderBottom: "0px solid rgba(224, 224, 224, 1)",
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText},
        head:{backgroundColor: theme.colors.background.secondary,
          borderBottom: "0px solid rgba(224, 224, 224, 1)",
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText},
        root: {
           backgroundColor: theme.colors.background.secondary,
           borderBottom: "0px solid rgba(224, 224, 224, 1)",
           fontFamily: theme.typography.fontFamily,
           fontSize: theme.typography.fontSize,
           color: theme.colors.primary.text
         }
       },
     },
  });
*/
const table_theme = createTheme({
  overrides: {
    MuiFormControl:{
    root:{
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      color: theme.colors.primary.contrastText
    }
    },
    MuiInputBase:{
      input:{
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        color: theme.colors.primary.contrastText
      }
    },
    MuiTableCell: {
      body:{
        backgroundColor: theme.colors.background.primary,
        border: `1px solid ${theme.colors.background.primary}`,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        color: theme.colors.text.maxContrast,
      },
      head:{
        backgroundColor: theme.colors.background.primary,
        border: `1px solid ${theme.colors.background.primary}`,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        color: theme.colors.text.maxContrast,
      },
      root: {
         backgroundColor: theme.colors.background.primary,
         border: `1px solid ${theme.colors.background.primary}`,
         fontFamily: theme.typography.fontFamily,
         fontSize: theme.typography.fontSize,
         color: theme.colors.text.maxContrast,
         padding: '5px 5px', // Adjust the padding as needed
       }
     },
   },
});
   //todo předělat alerty na zobrazení in
   interface FlexContainerStyle {
    display: string;
    float: 'left' | 'right' | 'none'; // Specify the valid values for float
  }
  
  const flexContainerStyle: FlexContainerStyle = {
    display: 'flex',
    float: 'left' // Or 'right', or 'none' based on your requirement
  };
   //mapování do listu optionu a.k.a nastav jméno a values to select listu (takový ten dropdown dolů)
   const options_: Option[] = devices.map((e)=>({
    value: e.id.toString(),
    label: `${e.name} @${e.id}`

   }));

  
  return (

<ThemeProvider theme={table_theme}>
<CustomScrollbar>
<DropdownCheckboxForm
options = {options_}
selectedOptions={selectedOptions}
setSelectedOptions={setSelectedOptions}
setSelectedShift={setSelectedShift}
selectedShift={selectedShift}
name={name}
setName={setName}
email={email}
setEmail={setEmail}
onSubmit={handleFormSubmit}

/>

<TableContainer 
      component={Paper}
      style={{
          maxHeight: height, // Přidána dynamická výška podle parametru height
          overflowY: 'auto'  // Přidáno pro rolování obsahu
        }}
        elevation={0} // Nastaví elevation na 0, čímž odstraní stín
      >
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Název skupiny</TableCell>
          <TableCell>Zařízení ve skupině</TableCell>
          <TableCell>Směna</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Četnost reportů</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>

      {ReportT.map((row, index) => {
    const deviceElements = [];
      const shiftElements = [];
      for (let shiftIndex = 0; shiftIndex < row.settings.shift.length; shiftIndex++) {
        const se = row.settings.shift[shiftIndex];
        shiftElements.push(
          <div style={flexContainerStyle}>{se}&nbsp; </div>
        );
      }

    for (let deviceIndex = 0; deviceIndex < row.settings.deviceList.length; deviceIndex++) {
      const device = row.settings.deviceList[deviceIndex];
      const deviceName = row.settings.deviceNames[deviceIndex];
      const combinedDevice =  `${deviceName} @${device}`; // Combine device and deviceName
      
      deviceElements.push(
        <Tooltip content={combinedDevice} key={deviceIndex}>
          <div style={flexContainerStyle}>{device}&nbsp; </div>
        </Tooltip>
      );
    }

    return (
      <TableRow key={index}>
      <TableCell>
        <Input
          disabled={true}
          value={row.group_name}
        />
      </TableCell>
      <TableCell>
        {deviceElements}

     

      </TableCell>
      <TableCell>

      {shiftElements}
      {/*
              <TextField
                value={row.settings.shift}
                onChange={(e) => handleChange(e, index, 'name')}
                error={Boolean(formError[index].show)}
                helperText={formError[index].message}
              />

    */}
            </TableCell>
            <TableCell>
            <Input
                value={row.settings.email}
              disabled={true}
              />

              
            </TableCell>

            <TableCell>
            <Input
                value={row.settings.reportRate==="weekly"?"Týdenní":"Denní"}
                disabled={true}
              />
            </TableCell>

            <TableCell>
            <Button size="md" variant='destructive' onClick={()=>setReportT(prevDevices => prevDevices.map((device, i) => i === index ? { ...device, showModal: true } : device))} >Smazat</Button>
            <ConfirmModal isOpen={row.showModal}
            title={"Smazat report"}
             body={"Opravdu chcete smazat report?"}
              description={"Report bude smazán."} 
              confirmText={"Smazat"} confirmButtonVariant={"primary"}
               dismissText={"Zrušit"} icon={"exclamation-triangle"}
                onConfirm={()=>handleDelete(row,index)} onDismiss={()=>setReportT(prevDevices => prevDevices.map((device, i) => i === index ? { ...device, showModal: false } : device))} />

            </TableCell>

          </TableRow>
    );
                })}
        {/*
        {ReportT.map((row, index) => (
          <TableRow key={index}>
            <TableCell>

              <TextField
               disabled={true}
                value={row.group_name}
                
              />
            </TableCell>
            <TableCell>
              <TextField
                value={row.settings.deviceList}
                onChange={(e) => handleChange(e, index, 'coef')}
                error={Boolean(formError[index].show)}
                  helperText={formError[index].message}
               
              />
            </TableCell>

            <TableCell>
              <TextField
                value={row.settings.shift}
                onChange={(e) => handleChange(e, index, 'name')}
                error={Boolean(formError[index].show)}
                helperText={formError[index].message}
              />

              
            </TableCell>
            <TableCell>
              <TextField
                value={row.settings.email}
                onChange={(e) => handleChange(e, index, 'name')}
                error={Boolean(formError[index].show)}
                helperText={formError[index].message}
              />

              
            </TableCell>

            <TableCell>
              <TextField
                value={row.settings.reportRate}
                onChange={(e) => handleChange(e, index, 'name')}
                error={Boolean(formError[index].show)}
                helperText={formError[index].message}
              />
            </TableCell>

            <TableCell>
            <Button size="md" variant='destructive' onClick={() => setModal(true)} >Smazat</Button>
            <ConfirmModal isOpen={modal} title={"Smazat report"} body={"Opravdu chcete smazat report?"} description={"Report bude smazán."} confirmText={"Smazat"} confirmButtonVariant={"primary"} dismissText={"Zrušit"} icon={"exclamation-triangle"} onConfirm={()=>handleDelete(index)} onDismiss={()=>setModal(!modal)} />

               
            </TableCell>

          </TableRow>
          
        ))}
        */}

  
      </TableBody>
    </Table>
   
    <div>
  <div>
</div>


</div>
  </TableContainer>
  </CustomScrollbar>
  </ThemeProvider>
  );
};
