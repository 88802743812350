import React, { useState } from 'react';

import { Select, Button, Form, Input,InlineLabel, Field } from '@grafana/ui';

interface Option{
  value: string;
  label: string;
}

interface DropdownCheckboxFormProps {
  options: Option[]; // Update the type of the 'options' prop
  selectedOptions: Option[];
  setSelectedOptions: (selected: Option[]) => void;
  selectedShift: Option[];
  setSelectedShift: (selected: Option[]) => void;
  name: string;
  setName: (name: string) => void;
  email: string;
  setEmail: (email: string) => void;
  onSubmit: (
    selectedShift: Option[],
    selectedOptions: Option[],
    name: string,
    email: string
  ) => void;
   // Replace 'any' with the actual type of 'styles'
}

const DropdownCheckboxForm: React.FC<DropdownCheckboxFormProps> = ({
  options,
  selectedOptions,
  setSelectedOptions,
 selectedShift, 
 setSelectedShift,
  name,
  setName,
  email,
setEmail,
  onSubmit,
  
}) => {

  const [nameError, setNameError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [selectedDevicesError,setSelectedDevicesError] = useState<string | null>(null);

  const handleOptionChange_devices = (selected: any) => {
    setSelectedOptions(selected);
  };
  const handleOptionChange_shifts = (selected: any) => {
    setSelectedShift(selected);
  };
  /*
  const handleOptionChange_shifts = (selected: Option | null) => {
    setSelectedShift(selected);
  };
  */
 
  const handleSubmit = (e: React.FormEvent) => {
    //e.preventDefault();
    //const shiftValue = selectedShift ? selectedShift.value : 'ranni';
       // Validate name
       if (!name.trim()) {
        setNameError('Název skupiny je povinný.');
      } else {
        setNameError(null);
      }
      
  
      // Validate email
      if (!email.trim()) {
        setEmailError('Email je povinný.');
      } else if (!isValidEmail(email)) {
        setEmailError('Zadejte platnou emailovou adresu.');
      } else {
        setEmailError(null);
      }
      if(selectedOptions.length  <= 0 ){
        setSelectedDevicesError("Zvolte zařízení");
      }else{
        setSelectedDevicesError(null);
      }
      // If both name and email are valid, proceed with submission
      if (!nameError && !emailError && !selectedDevicesError) {
        onSubmit(selectedShift, selectedOptions, name, email);
         setSelectedOptions([]);
    setName('');
    setEmail('');
      }
    //onSubmit(selectedShift,selectedOptions, name, email);
    // Reset form fields
    //setSelectedOptions([]);
    //setName('');
    //setEmail('');
  };
  const isValidEmail = (email: string) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const shiftList = [
    { value: 'ranni', label: 'Ranní' },
    { value: 'odpoledni', label: 'Odpolední' },
    { value: 'nocni', label: 'Noční' },
  ];

  return (
    <div>
      <Form 
      onSubmit={handleSubmit}
      >
      {({ register, errors }) => (
          <>
  <Field label="Název skupiny" invalid={!!nameError} error={nameError}>
        <Input
          type="text"
          value={name}
          onChange={(e) => {
            setNameError(null);
            setName(e.currentTarget.value); 
            }}
          placeholder="Název skupiny"
        />
            </Field>
      
      <Field label="E-mail" invalid={!!emailError} error={emailError}>
        <Input
          type="Email"
          value={email}
          onChange={(e) => {setEmail(e.currentTarget.value);
                            setEmailError(null);}}
          placeholder="Email"
        />
        </Field>

 

<Field label="Zvolte zařízení: " invalid={!!selectedDevicesError} error={selectedDevicesError}>
        <Select
          options={options}
          isMulti
          onChange={(e)=>{handleOptionChange_devices(e);setSelectedDevicesError(null);}}
          value={selectedOptions}
        />
        </Field>
        <InlineLabel width="auto" tooltip="Tooltip content">
          
Směny
</InlineLabel>
       
        <Select isMulti options={shiftList} value={selectedShift} onChange={handleOptionChange_shifts}/>
        <Button  
        type="submit"
        size="md"
        variant='primary'
        fill="solid"
        
         >Přidat</Button>
        </>
        )}
      </Form>
      
    </div>
  );
};

export default DropdownCheckboxForm;
